.header-container {
  height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.header-left-container {
  width: 50%;
}
.header-right-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
.header-menus-messageBox {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background:#FEFEFF;
  box-shadow: 0px 20px 20px #2948981A;
  cursor: pointer;
}
.header-menus-messageBox>button>img{
    height: 20px;
    width: 20px;
}
.header-menus-messageBox>button{
    background-color: #FEFEFF;
    border: none;
}
.header-menus-avatar {
  height: 50px;
  width: 50px;
}
.header-menus-avatar>img{
    height: inherit;
    width: inherit;
}
.header-menus-profile>#name{
    color: #30376A;
    font: normal normal bold 15px/22px Poppins;
}
.header-menus-profile>#email{
    color: #30376A;
    opacity: 0.69;
    font: normal normal bold 12px/22px Poppins;
}