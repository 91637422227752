.result-container {
  border-radius: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.result-left-container {
  border: 1px solid green;
  border-radius: 15px;
  height: 100%;
  width: 70%;
  box-shadow: 0px 15px 16px #a4a7ac29;
  border: 1px solid #ededf8;
  padding:15px;
}
.result-right-container {
  border: 1px solid green;
  border-radius: 15px;
  height: 100%;
  width: 30%;
  box-shadow: 0px 15px 16px #a4a7ac29;
  border: 1px solid #ededf8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: scroll;
}
.rule-card {
  position: relative;
  border: 1px solid #b3f5e0;
  border-radius: 5px;
  background: #f8fbff;
  height: 100px;
  width: 100%;
  padding: 10px 0px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.rule-name,
.formula,
.description {
  font-size: 13px;
  font-family: poppins;
  color: #bcbaba;
}
.rule-name {
  color: #43434a;
  font-weight: 600;
}
.status-button {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  top: -8px;
  right: -8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Spreadsheet {
  height: 100%;
  width: 100%;
  overflow: auto;
}
