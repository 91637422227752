.drag-and-drop-div {
  display: flex;
  position: relative;
  border-radius: 10px;
  opacity: 1;
  margin: 0;
  width: 100%;
  /* height: 400px; */
  cursor: pointer;
  min-height: 200px;
  max-height: 400px;
}
.drag-and-drop-div > label {
  width: 100%;
  cursor: pointer;
  /* height: 400px; */
  min-height: 200px;
  max-height: 400px;
}
#drag-and-drop {
  opacity: 0;
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  /* height: 400px; */
  min-height: 200px;
  max-height: 400px;
}
.input-drag-drop-div {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: dashed 2px #50dfb2;
  width: 100%;
  padding: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1;
  min-height: 200px;
  /* height: 400px; */
  gap: 15px;
}
.input-drag-drop-div > h1 {
  font-size: 25px;
  font: normal normal medium 25px/38px Poppins !important;
  margin: 0px;
  cursor: pointer;
}

.input-drag-drop-div > h1 > span {
  color: #50dfb2;
  cursor: pointer;
}
.input-drag-drop-div > h3 {
  font-size: 18px;
  color: #a9aeac;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}
.horizental-row {
  border: 1px solid #e1e1e6;
  width: calc(100% - 30px);
}
.horzontal-row-container {
  width: 300px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.horizontal-row-division {
  border: 1px solid #e1e1e6;
  border-radius: 100%;
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #e1e1e6;
}

.summary-card {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 13px;
  padding: 0 13px;
  position: relative;
  opacity: 1;
  width: 35%;
  height: 120px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.admin-summary-card {
  width: 47%;
  height: 150px;
}

.summary-card-number {
  border-radius: 12px;
  width: 20%;
  display: flex;
  margin: 20px 0 0 0;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 2px !important;
  font-family: Poppins;
  letter-spacing: 0px;
}

.summary-main-div {
  height: 20%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 1.3%;
}
.admin-summary-main-div {
  height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  flex-wrap: wrap;
}
.admin-card-sub-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.summary-card-data {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
}
.summary-card-headings {
  height: 50%;
  position: relative;
  padding: 15px 0 0 10px;
}

.summary-card-headings > p {
  position: relative;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0px;
}
.summary-card-headings > h4 {
  position: relative;
  margin: 0;
  font-size: 20px;
}
.summary-card-view-list {
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: #a9aeac;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}

.task-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  padding-bottom: 10px;
}

.table-header {
  background-color: "#EEF3F6" !important;
  border: "2px solid red";
  width: "200px" !important;
  height: "20px" !important;
}

.summary-card-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary-card-icon > img {
  width: 15px;
  height: 15px;
}
.upload-file-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-file-list-icon {
  width: 5%;
  height: 100%;
  font-size: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.upload-file-list-body-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 85%;
}
.upload-file-list-name-pc-container {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: poppins;
  font-weight: 700;
}
.upload-file-list-progressbar > progress {
  width: 100%;
  height: 8px;
  border: 1px solid transparent;
  border-radius: 10px;
}
.upload-file-list-progressbar > progress::-webkit-progress-value {
  border-radius: 30px;
  background: #50dfb2;
}
.upload-file-list-progressbar > progress::-webkit-progress-bar {
  background-color: #e3e9e7;
  border-radius: 10px;
}
.upload-file-list-buttons {
  width: 5%;
  display: inline-flex;
  justify-content: center;
  padding-left: 10px;
  gap: 10px;
  height: 100%;
  align-items: center;
  padding-top: 10px;
}
.upload-file-list-buttons > button {
  background: transparent;
  border: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.upload-file-list-buttons > button:first-child {
  background-color: #d2d5f6;
}
.upload-file-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.upload-file-button {
  width: fit-content;
  max-width: 10%;
  overflow: hidden;
  height: 35px;
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 15px;
  background-color: white;
  border: 1px solid #50dfb2;
  color: #50dfb2;
  padding: 10px;
  cursor: pointer;
}
.upload-file-button:hover {
  border: none;
  color: white;
  background-color: #50dfb2;
}
.table-back-button {
  background-color: #50dfb2;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
