.dragger {
  position: relative;
  border: 3px dotted grey;
  width: 100%;
  margin: 0 10px;
  padding: 12px;
  min-height: 70px;
}

.sidebar-logo {
  text-align: center;
  padding-top: 0.8rem;
}

.sidebar-logo > img {
  width: 110px;
  object-fit: contain;
}

.logo__circle-R,
.logo__circle-sidebar {
  position: relative;
  width: 11px;
  height: 11px;
  border-radius: 500px;
  border: 1.2px solid rgb(180, 180, 180);
}

.logo__circle-R::after {
  position: absolute;
  content: "R";
  top: 50%;
  left: 50%;
  transform: translate(-41%, -50%);
  font-size: 0.4rem;
  color: rgb(180, 180, 180);
}

.logo__circle-sidebar::after {
  position: absolute;
  content: "R";
  top: 50%;
  left: 50%;
  transform: translate(-27%, -50%);
  font-size: 0.5rem;
  color: rgb(180, 180, 180);
}

.dragger input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
}

.drop-new-pdf,
.drop-new-chat {
  color: white;
  font-family: inherit;
  font-size: 1rem;
  margin: 0;
}

.drop-new-chat {
  display: flex;
  align-items: center;
  gap: 4px;
}

.drop-new-pdf {
  color: gray;
  font-size: 0.85rem;
}

.file_list_title {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.file_list_btn {
  width: auto !important;
  height: auto !important;
  padding: 6px !important;
  min-width: auto !important;
  background: white !important;
}

.file_list_item_name {
  width: 178px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  margin: 0;
}

.file_list_item-active {
  background: #1677ff !important;
}

.MuiCheckbox-root,
.MuiCheckbox-colorPrimary {
  color: #1677ff !important;
}

.MuiSvgIcon-root,
.MuiSvgIcon-fontSizeMedium .css-i4bv87-MuiSvgIcon-root {
  color: white !important;
}

.chat-main {
  background: #001529 !important;
  height: 100% !important;
  flex: 1 !important;
}

.chat-main-box {
  flex: 1 !important;
  overflow-y: auto !important ;
  padding: 1rem 1rem 1rem 1rem !important;
}
.chat-main-input {
  padding: 1rem 1rem 1.5rem 1rem !important;
}

.ai-response,
.ai-query {
  /* background-color: #f9f9fe; */
  background-color: transparent !important;
  width: fit-content !important;
  padding: 0.8rem !important;
  border-radius: 5px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.ai-query {
  background-color: #1777ff !important;
  padding: 0.5rem 0.8rem !important;
  border-radius: 10px !important;
}

.ai-query span,
.ai-response span {
  font-size: 0.8rem;
  font-weight: 400;
  color: white;
  text-align: left !important;
  word-break: break-all;
}

.ai-query > span {
  text-align: left !important;
  color: white !important;
  font-weight: 400 !important;
  line-height: normal;
}

.chat-search-box {
  position: relative;
  overflow: hidden;
  border: 1.5px solid transparent;
}

.chat-search-box:hover {
  border-color: #1677ff !important;
}

.chat-search-box > input[type="text"] {
  width: 85%;
  padding: 0.5rem 0.8rem;
  font-weight: 500;
  color: black;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.chat-search-box > .chat-search-btn {
  background: #1677ff;
  display: flex !important;
  flex: 1 !important;
  height: 100%;
  gap: 5px;
  border-radius: 0px !important;
}

.chat-search-btn:hover {
  background: #1677ff !important;
}

.chat-search-btn > .chat-search-progress {
  width: 25px !important;
  height: 25px !important;
}

.chat-search-box > .chat-search-btn > svg {
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
}

.chat-search-btn:disabled {
  background: #1677ff !important;
  opacity: 0.7;
}

.MuiCircularProgress-root {
  color: #cee5fb !important;
}

.pdf-file-viewer {
  width: 49%;
  max-width: 1000px;
  height: 100vh;
  background-color: #001529;
}

.chatbot_sidebar_container::-webkit-scrollbar {
  display: none;
}

.custom_textarea {
  border-radius: 5px;
  transition: all 0.05s linear;
  font-size: 0.9rem;
  margin-top: 1rem;
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  padding: 0.5rem;
}

.custom_textarea:focus {
  box-shadow: 0px 0px 7px -2px rgba(21, 119, 255, 0.62);
}

/* .carousel-root {
  height: 100%;
  .carousel-slider {
    height: 100%;
    .slider-wrapper,
    .axis-horizontal {
      height: 100% !important;
      .slider {
        height: 100% !important;
      }
    }
  }

  .control-dots {
    .dot {
      background-color: #1677ff !important;
    }
  }

  .carousel-status {
    font-size: 0.8rem !important;
  }
} */

.conversation_main {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, auto);
  gap: 10px;
  max-width: 900px;
}

.conversation_main_box {
  overflow: hidden;
  background: rgba(255, 255, 255, 0.02);
  /* // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    // backdrop-filter: blur(8.5px);
    // -webkit-backdrop-filter: blur(8.5px); */
  border-radius: 10px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  max-height: 140px;
  cursor: pointer;
  padding: 1rem;
  position: relative;
}

.conversation_main_box > span {
  color: lightgray;
  font-weight: 400;
  font-size: 0.7rem;
  text-align: center;
}

.conversation_main_box:before {
  content: "";
  position: absolute;
  opacity: 0;
  transition: opacity 500ms;
  inset: 0;
  z-index: 2;
  border-radius: inherit;
  background: radial-gradient(
    600px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.2),
    transparent 20%
  );
}

.conversation_main_box:hover:before {
  opacity: 1;
}

/* Loader starter */

@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.honeycomb {
  height: 24px;
  position: relative;
  width: 24px;
}

.honeycomb div {
  -webkit-animation: honeycomb 2.1s infinite backwards;
  animation: honeycomb 2.1s infinite backwards;
  background: #f3f3f3;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  width: 24px;
}

.honeycomb div:after,
.honeycomb div:before {
  content: "";
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}

.honeycomb div:after {
  top: -6px;
  border-bottom: 6px solid #f3f3f3;
}

.honeycomb div:before {
  bottom: -6px;
  border-top: 6px solid #f3f3f3;
}

.honeycomb div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}

.honeycomb div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}

.honeycomb div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}

.honeycomb div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}

.honeycomb div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}

.honeycomb div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}

.honeycomb div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}

/* FILE LOADER */

.file__loader {
  color: rgb(0, 0, 0);
  background: linear-gradient(to right, #2d60ec, #3ccfda);
  font-size: 22px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate8345 5s linear infinite;
  font-weight: bold;
}

@keyframes animate8345 {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(360deg);
  }
}

/* CHAT LOADER */

.chat__loader,
.chat__loader:before,
.chat__loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.chat__loader {
  color: #1777ff;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  margin-left: 1.2rem;
}
.chat__loader:before,
.chat__loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.chat__loader:before {
  left: -4em;
  animation-delay: -0.32s;
}
.chat__loader:after {
  left: 4em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* ONE PAGE REPORT CSS */

.report_main_header {
  position: relative;
  /* overflow-x: hidden; */
  overflow: hidden;
  min-height: 50px;
}
.report_main_header::after {
  content: "";
  position: absolute;
  background: #003c4f;
  top: 0;
  right: 0;
  transform: skewX(-30deg) translateX(10%);
  width: 40%;
  height: 100%;
  z-index: 2;
}
.report_main_header_sec-1,
.report_main_header_sec-2 {
  color: white;
  padding: 0.8rem 1rem !important;
  font-weight: 500;
  text-transform: uppercase;
}

.report_sidebar-logo {
  position: absolute;
  left: 0;
  background: white;
  padding: 8px;
  top: 0px;
  left: 6%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.report_main_header_sec-2 {
  z-index: 3;
  background: transparent;
  text-transform: none;
  font-size: 0.8rem;
  font-style: italic;
}

.report_table_container {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.report_table_title {
  background-color: #003c4f;
  color: white;
  font-size: 0.8rem;
  padding: 5px 0px;
  text-align: center;
  border: 1px solid #dddddd;
}

.report_table_box {
  border-collapse: collapse;
  width: 100%;
}

.report_table_head,
.report_table_cell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 0.7rem;
}

.report_table_row:first-child {
  background-color: #003c4f;
  color: white;
}

.report_table_row:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

/* EXCEL PREVIEW */

.excel_table {
  table-layout: auto;
  width: auto;
  border: 1px solid #b9b9b9;
  border-collapse: collapse;
  margin: 0 auto;
  border-radius: 10px;
  /* overflow: hidden; */
}

.excel_table_head {
  border: 1px solid #b9b9b9;
  border-collapse: collapse;
}

.excel_table_row:hover > .excel_table_data {
  background-color: rgb(243, 243, 243);
  color: black;
}

.excel_table > tbody > tr {
  /* background: #001529; */
  background: white;
}

.excel_table_data {
  border: 1px solid #b9b9b9;
  color: black;
  font-size: 0.8rem;
  font-weight: 400;
}

.excel_table_head {
  /* background-color: #021c35; */
  background-color: #3d5af1;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
}

.excel_table_head {
  padding: 10px 0px;
}

.excel_table_input {
  outline: none;
  /* width: 100%;
  height: 100%; */
  /* max-width: 120px; */
  width: fit-content !important;
  border: none;
  background: transparent;
  color: inherit;
}

.excel_table_input:focus-within {
  outline: none;
  border: 2px solid #1677ff;
  /* -webkit-box-shadow: -1px 3px 15px 2px rgba(23, 119, 255, 1);
  -moz-box-shadow: -1px 3px 15px 2px rgba(23, 119, 255, 1);
  box-shadow: -1px 3px 15px 2px rgba(23, 119, 255, 1); */
}

.remove-column-icon {
  display: none; /* Default display */
}

.excel_table_head:hover .remove-column-icon {
  border-radius: 500px;
  background-color: red;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}

/* CARD VIEW */
/* From Uiverse.io by Yaya12085 */
.card_view {
  max-width: 250px;
  background-color: rgb(7 16 45);
  background: linear-gradient(to top right, rgb(7 16 45), rgb(58 60 84));
  background: -webkit-linear-gradient(
    to top right,
    rgb(7 16 45),
    rgb(58 60 84)
  );
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid rgb(84 90 106);
  cursor: pointer;
}

.card_view .small-text,
.title,
.desc {
  font-weight: 600;
}

.card_view .title,
.desc {
  margin: 8px 0;
}

.card_view .small-text {
  color: #488aec;
  font-size: 14px;
}

.card_view .title {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
}

.card_view .desc {
  color: rgb(151 153 167);
  font-size: 10px;
}

.card_view .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.card_view .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 6px;
  text-decoration: none;
}

.card_view .button:first-child {
  box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
  background-color: #488aec;
}

.card_view .button:last-child {
  box-shadow: 0 4px 6px -1px #0b162531, 0 2px 4px -1px #0e192b17;
  background-color: #fff;
}

.card_view .icon {
  height: 25px;
  width: 25px;
}

.card_view .icon svg {
  width: 100%;
  height: 100%;
}

.card_view .icon svg:last-child {
  fill: #000;
}

.card_view .button-text {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  color: black;
}

.card_view .button-text span:first-child {
  font-size: 12px;
  font-weight: 600;
}

.resizer {
  cursor: ew-resize;
  width: 20px;
  background: transparent;
  height: 100%;
  position: relative;
}

.resizer:hover::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 50%;
  width: 4px;
  height: 100%;
  transform: translateX(-50%);
  background-color: #1677ff;
  border-radius: 2px;
  z-index: 2;
}

.resizer::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 33px;
  transform: translate(-50%);
  background-color: gray;
  border-radius: 2px;
}
