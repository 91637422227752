.main-container {
  display: flex;
  background-color: #eceef0;
  height: 100vh;
  width: 100vw;
}
.right-container {
  width: 83%;
  display: flex;
  flex-direction: column;
}
.collapsed-right-container {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}
.dashboard-div {
  gap: 10px;
  display: flex;
  position: relative;
}

.right-container-content {
  padding: 20px;
  position: relative;
  gap: 15px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;
}
.uplaod-progress {
  width: 80px;
  height: 35px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #50dfb2;
  color: #50dfb2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-dasbhboard-table-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 34px 68px #0d2e610d;
  border-radius: 15px;
  width: 65%;
}
.admin-dashboard-table-head {
  height: 60px;
  background: #f3f6f8 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: left;
}
.admin-dashboard-table-head {
  text-align: left;
  letter-spacing: 0px;
}
.admin-dashboard-table-tab {
  cursor: pointer;
  margin-left: 20px;
}
.admin-dashboard-table-head-active {
  font: normal normal medium 15px/25px Poppins;
  color: #50dfb2;
  cursor: pointer;
  text-align: left;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
}
.admin-dashboard-table-head-active span {
  border: 3px solid #50dfb2;
  display: block;
}
.admin-dasbhboard-job {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 34px 68px #0d2e610d;
  border-radius: 15px;
  width: 35%;
  height: 80vh;
}
.admin-dashboard-job-wrapper {
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: 580px;
}
.admin-dashboard-job-head {
  color: #111026;
  margin-top: 15px;
  font: normal normal normal 18px/25px;
  letter-spacing: 0px;
}
.admin-dashboard-job-body {
  overflow-y: auto;
  height: fit-content;
}
.job-card {
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
}
.job-card-avatar {
  width: 60px;
}
.job-card-data {
  display: flex;
  flex-direction: column;
  width: 70%;
  overflow: hidden;
}
.job-card-data p {
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  &:first-of-type {
    font-weight: 600;
  }
}
.job-card-job {
  background: #555555 0% 0% no-repeat padding-box;
  padding: 2px 4px;
  color: white;
  border-radius: 6px;
  width: 30px;
  text-align: center;
}
