.dashboard-input-searchbar {
  border: 1px solid #a9aeac;
  width: 309px;
  height: 35px;
  border-radius: 24px;
  /* overflow: hidden; */
  z-index: 10 !important;
  padding: 2px;
  margin-top: 10px;
  margin: 0;
  display: flex;
}
.dashboard-input-searchbar > input {
  border: none;
  width: 100%;
  margin: 0;
  z-index: 10 !important;
  height: 30px;
  outline: none;
  padding-left: 8px;
  border-radius: 24px;
}
.dashboard-input-searchbar > button {
  width: 10%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  background-color: transparent;
  z-index: 10 !important;
  color: #a9aeac;
  border: none;
}
.add-user-button {
  font-size: 23px !important;
  background-color: #50DFB2 !important;
}
