.task-container {
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 34px 68px #0d2e610d;
  border-radius: 15px;
  position: relative;
  min-width: 90%;
  min-height: 540px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-heading {
  position: relative;
  height: 10%;
  color: #100101;
  font-size: 20px;
  font-family: poppins;
  font-weight: 600;
  text-transform: capitalize;
}

.table-header {
  background-color: "#EEF3F6";
  border: "2px solid red";
}

.table-body-container {
  height: 90%;
  overflow: auto;
}

.apply-recon-table-body-container {
  height: 440px;
  overflow: auto;
  width: 48%;
  box-shadow: 0px 15px 16px #A4A7AC29;
  opacity:1;
  padding-bottom: 5px;
}
.task-heading-button-container {
  display: flex;
  gap: 10px;
}
.task-heading-button-container > Button {
  display: flex;
  gap: 3px;
  font-weight: 600;
}

.table-action-column {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.table-action-column > button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background: transparent;
}


button:disabled{
  /* background-color: #ccc !important; */
  color: #555 !important;
  outline: 'none' !important;
  border: "1px solid #555" !important;
  border-color: "#555" !important;
  cursor: not-allowed
}