.container {
  width: 240px;
  height: 100%;
  background-color: #eceef0;
  position: relative;
  min-width: 50%;
  top: 0px;
  display: flex;
  justify-content: center;
}

.sidebar {
  height: 100%;
  width: 240px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #000000db 20%, #000000 20%);
}

.options {
  height: 60px;
  width: 240px;
  padding-inline-start: 0px;
  font-size: 14px;
  font-weight: 700;
  flex-grow: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: none hidden;
}

.sidebar-option {
  color: #eceef0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  height: 50px;
}
.sidebar-option.li {
  width: 100%;
  display: flex;
  padding-left: 15px;
}
.sidebar-option.selected {
  position: relative;
  background-color: #eceef0;
  color: #0e0e4f;
  border-right: 2px solid #eceef0;
  width: 100%;
  border-radius: 50px 0px 0px 50px;
  height: 40px;
}
.sidebar-option.selected::before {
  position: absolute;
  content: "";
  height: 40px;
  width: 60px;
  right: -2px;
  top: -40px;
  border-right: 1px solid #eceef0;
  border-radius: 60px 0px 30px 0px;
  background-color: #000000;
  box-shadow: 10px 10px 0px 0px #eceef0;
}
.sidebar-option.selected::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 60px;
  right: -2px;
  border-right: 1px solid #eceef0;
  bottom: -40px;
  border-radius: 0px 30px 0px 60px;
  background-color: #000000;
  box-shadow: 10px -10px 0px 0px #eceef0;
}
a {
  color: black;
  text-decoration: none;
}

.sidebar-buttons {
  border-radius: 10px;
  color: #eceef0;
  border: none;
  cursor: pointer;
}

#login-button {
  background-color: #007bff;
  border-radius: 10px;
  padding: 8px 16px 9px 16px;
}

#try-button {
  border-radius: 10px;
  padding: 8px 16px 9px 16px;
  background-image: linear-gradient(to top, #b224ef 0%, #7579ff 100%);
}

.sidebar-icon {
  margin-left: 10px;
}
.sidebar-icon-selected {
  border-left: 3px solid blue;
  padding-left: 8px;
  height: 28px;
  display: flex;
  align-items: center;
}

.main-sidebar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon-sidebar {
  width: 50px;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
}

.logo {
  height: 70px;
  width: 140px;
}
.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100px;
  gap: 20px;
  justify-content: space-between;
  z-index: 20;
  padding: 0 20px 0 0;
  margin-bottom: 20px;
}
.sidebar-menu-button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.sidebar-menu-button > img {
  width: 20px;
  height: 20px;
}

.collapsed-sidebar {
  width: 50px;
  overflow: hidden;
  height: 100%;
  transition: all 0.4s;
}
