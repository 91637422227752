.ruleset-empty-screen-container {
  border: 1.5px solid #e4e7eb;
  border-radius: 5px;
  height: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#ruleset-empty-screen-container-content > p:last-child,
#ruleset-empty-screen-container-content > p:first-child {
  color: #cdcece;
  font-size: x-large;
  font-family: Poppins;
  text-align: center;
  margin: 0;
  padding: 5px;
}
#ruleset-empty-screen-container-content > p:last-child {
  font-size: small;
}
#ruleset-empty-screen-container-image {
  background-color: #eff0f1;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-name-container,
.template-name-container > input {
  width: 300px;
  height: 35px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cdcece;
  outline: none;
}
.template-name-container > input {
  padding: 10px;
}

.template-name-container > input:focus {
  border: 1px solid #50dfb2;
}
