@import "./SubComponent.css";
@import "./searchbar.css";
@import "./dashboard.css";
@import "./sidebar.css";
@import "./Tasks.css";
@import "./header.css";
@import "./ruleset.css";
@import "./RulesetIntermidiate.css";
@import "./reconciliatedResult.css";
@import "./filter.css";
@import "./PDFMasking.css";
@import "./chatbot.css";
* {
  font-family: poppins !important;
}
button:disabled {
  color: #bdbdbd;
  border: 1px solid #bdbdbd !important;
  background: transparent !important;
}
::-webkit-scrollbar {
  border: 1px solid transparent;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(28, 27, 27, 0.171);
  border-radius: 40px;
}

.cancel-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #ac2734 0% 0% no-repeat padding-box;
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
  color: white;
}

.Toastify__toast {
  border-radius: 10px;
  min-height: 20px;
}

.Toastify__toast-icon {
  width: 23px;
  margin-right: 15px;
}

.Toastify__toast-body {
  font-size: 12px;
}
@media only screen and (min-width: 1900px) {
  .container {
    width: 260px;
  }
  .sidebar {
    width: 260px;
  }
  .sidebar-option {
    width: 100%;
  }
  .options {
    width: 260px;
  }
}
.loader-container {
  position: absolute;
  pointer-events: all;
  z-index: 999999999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000030;
}
.loader-container-2 {
  position: absolute;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
