.template-content-form,
.template-content {
  border: 1px solid #e4e7eb;
  min-height: 300px;
  height: 80%;
  max-height: 100%;
  background: #f8fbff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.template-content > heading {
  padding: 20px;
  font-weight: 600;
  font-family: Poppins;
  margin: 0;
}
.template-content > templateContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 350px;
  overflow: auto;
  padding: 20px;
  gap: 20px;
}
.csv-template-container {
  height: 308px;
  min-width: calc(calc(100% / 3) - 15px);
  width: calc(calc(100% / 3) - 15px);
  box-shadow: 0px 15px 16px #a4a7ac29;
  background: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.csv-template-container > templateHeading {
  text-align: center;
  width: 100% !important;
}
.excel-template-conatiner {
  overflow: auto;
}
.template-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.template-buttons > button {
  box-shadow: 0px 5px 15px #2948981a;
  height: 30px;
  width: 90px;
  border-radius: 10px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
.template-buttons > button:first-child {
  border: 1px solid #0e0e4f;
  background-color: white;
  color: #0e0e4f;
}
.template-buttons > button:last-child {
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-ruleset-buttons,
.add-delete-upload-csv {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  gap: 10px;
}
.choose-ruleset-buttons > button,
.add-delete-upload-csv > button {
  border: 1px solid #50dfb2;
  height: 35px;
  width: 80px;
  border-radius: 8px;
  text-transform: capitalize;
  font-family: poppins;
  cursor: pointer;
}
.choose-ruleset-buttons > button:first-child {
  color: #50dfb2;
  background: white;
}
.choose-ruleset-buttons > button:last-child {
  color: white;
  background-color: #50dfb2;
}
.template-content-form {
  flex-direction: row;
}
.template-form {
  width: 50%;
  display: flex;
  padding: 30px 20px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.form-ruleset-name,
.inner-form-input,
.form-add-new-button {
  height: 40px;
  width: 100%;
  padding: 5px;
  outline: none;
}
.form-ruleset-name,
.inner-form-input {
  border: 1px solid #e4e7eb;
  border-radius: 4px;
}
.form-add-new-button {
  border: 1px solid #e4e7eb;
  background: #50dfb2;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}
.template-inner-form,
.rule-table {
  padding: 10px 20px;
  background-color: white;
  height: 75%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
}
.form-ruleset-name::placeholder,
.inner-form-input::placeholder,
.form-add-new-button::placeholder,
.inner-form--text-area::placeholder {
  color: #d0d0d3;
}
.inner-form--text-area {
  height: 60px;
  width: 100%;
  border: 1px solid #e4e7eb;
  resize: none;
  padding: 5px;
}
.inner-form-action-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.inner-form-action-button > button {
  width: 60px;
  background: transparent;
  height: 25px;
  border: 1px solid #0d0e4f;
  border-radius: 5px;
  text-transform: capitalize;
  color: #0d0e4f;
}
.inner-form-action-button > button:last-child {
  background-color: #0d0e4f;
  color: white;
}
.template-inner-list {
  border: 1px solid green;
}
.rule-table {
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  padding: 0 20px;
}
.rule-table-cell {
  color: #b9b9bf !important;
  font-size: small !important;
  font-family: poppins !important;
  font-weight: 500 !important;
  text-overflow: ellipsis !important;
}
.upload-csv-container,
.build-table-container {
  height: 100%;
  width: 50%;
  box-shadow: 0px 15px 16px #a4a7ac29;
  background: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.build-table-container > div > button {
  outline: none;
  border: none;
  height: 35px;
  width: 70px;
  border-radius: 5px;
  color: white;
  background: #50dfb2;
  cursor: pointer;
}
.build-table-container > p {
  margin: 0;
  font-family: poppins;
  text-transform: capitalize;
  color: #7b7d7c;
}
.upload-csv-container > img {
  height: 100px;
  width: 100px;
}
#upload-csv-input-label {
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
}
.upload-csv-labeling {
  color: #50dfb2;
  font-size: larger;
  font-weight: 600;
  font-family: poppins;
  margin: 0;
}
#upload-csv-tag-line {
  color: #a9aeac;
  margin: 0;
  font-size: smaller;
}
.small-div-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 8px;
  width: 100%;
  height: 100%;
}
.outer-small-box {
  display: flex;
  gap: 10px;
}
.table-grid,
.highlighted-table-grid {
  border: 1px solid #a9aeac;
  height:30px;
  width: 30px;
  display: flex;
  border-radius: 5px;
}
#highlighted-table-grid {
  border: 2px solid #50dfb2;
  color: #50dfb2 !important;
}
.Spreadsheet__table {
  height: 100% !important;
}
.Spreadsheet__table > tbody > tr > th {
  background-color: white;
  color: #7b7d7c;
}
.add-delete-upload-csv > button {
  background-color: transparent;
  color: #50dfb2;
  border: 1px solid #50dfb2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  cursor: pointer;
}

.reconciliatied-back-to-dashboard {
  color: #0e0e4f;
  background-color: #eff0f1;
  height: 35px;
  width: 150px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}
