.pdf-masking-main-div {
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column !important;
  border-radius: 15px;
  overflow: auto;
}
.pdf-page-viewer {
  width: 100%;
  height: 150px;
  display: flex;
  gap: 10px;
  padding: 10px;
  overflow: hidden;
  position: relative;
}
.progress-bar-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  height: 40px;
  width: 80%;
}
.progress-bar-container > progress {
  width: inherit;
  height: 6px !important;
  background: #50dfb2;
  border-radius: 30px;
}
.progress-bar-container > progress::-webkit-progress-bar {
  background-color: #e3e9e7;
  border-radius: 30px;
  height: 6px !important;
}
.progress-bar-container > progress::-webkit-progress-value {
  border-radius: 30px;
  height: 6px !important;
  background: #50dfb2;
}

.page-viewer-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.pdf-viewer-container {
  display: flex;
  justify-content: space-between;
  height: 78vh !important;
  overflow-y: hidden;
  position: relative;
  gap: 5%;
  flex-direction: column;
}
.pdf-container {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.page-viewer-selection {
  border: 1px solid #43434542;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
}
.page-selection-conatiner {
  width: 20%;
  box-shadow: 1px 0px 10px 0px rgb(0 0 0 / 26%);
}
.page-selection-page-number {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background: black;
  color: white;
  height: fit-content;
  width: fit-content;
  padding: 3px;
  min-width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
}
.pdf-button-container {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  bottom: 15px;
  gap: 10px;
  flex-direction: column;
}
.pdf-action-buttons {
  background: #212222 !important;
  color: white !important;
  text-transform: capitalize;
  font-weight: 600;
}
.data-defination{
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.data-defination>dd{
  font-size: 14px;
}
.blink {
  animation: blinker .75s linear infinite;
  color: red;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@keyframes blinker {
  80% {
      opacity: 0;
  }
}