.searchFilterForm {
  border: 1px solid #bdbdbd;
  height: 50px;
  width: fit-content;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  gap: 10px;
  align-items: center;
  top: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.searchFilterForm > input {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.119);
  outline: none;
}
.searchFilterForm > input::placeholder {
  padding: 5px;
}
.searchFilterForm > button {
  border: 1px solid #08c489;
  border-radius: 5px;
  height: 30px;
  width: 50px;
  background-color: #08c489;
  color: white;
  cursor: pointer;
}
.filter-state-handler {
  border: none;
  margin-left: 10px;
  font-size: 15px;
  text-align: center;
  background: none;
}
.dateFilterForm {
  position: absolute;
  background: white;
  width: 200px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.119);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
}
.date-filter {
  height: 40px;
  width: 180px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #bdbdbd;
  padding: 5px;
}
.dateFilterForm > input[type="date"]:not(.has-value):before {
  color: rgb(168, 168, 168);
  content: attr(placeholder);
  width: 55px;
}
.apply-button {
  background-color: #50dfb2;
  border: 1px solid #50dfb2;
  outline: none;
  width: 160px;
  height: 28px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.reset-button {
  background: none;
  border: none;
  font-size: 19px;
  color: rgb(222, 3, 3);
}
.status-selection-form {
  position: absolute !important;
  width: 130px !important;
  z-index: 9999999999999 !important;
  background-color: white;
  top: 38px;
  left: 40px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.119) !important;
  padding: 5px 10px !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 5px !important;
}
